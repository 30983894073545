//import getters from './getters.js'
import actions from './actions.js'
export default {
	namespaced: true,
	state() {
		return {
			sitecfg: {}
		}
	},
	// getters,
	actions
}