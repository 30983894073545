import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


let routes = [{
	path: '/',
	name: 'main-layout',
	component: 'layouts/main',
	redirect: {
		name: 'index'
	},
	children: [
		{
			name: 'home',
			meta: {
				title: 'Home',
			},
			component: 'index/index',
			path: '/',
		},
		{
			meta: {
				title: 'Category',
			},
			component: 'category/_id',
			path:'category/:id'
		},
		{
			meta: {
				title: 'Game',
			},
			component: 'game/_id',
			path: 'game/:id'
		},
		{
			meta: {
				title: 'About',
			},
			component: 'pages/about',
			path: 'pages/about'			
		},
		{
			meta: {
				title: 'Contact',
			},
			component: 'pages/contact',
			path: 'pages/contact'			
		},
		{
			meta: {
				title: 'Privacy',
			},
			component: 'pages/privacy',
			path: 'pages/privacy'			
		},
		{
			meta: {
				title: 'Terms',
			},
			component: 'pages/terms',
			path: 'pages/terms'			
		},
		{
			redirect: {
				name: 'home'
			},
			path: '*'
		}
	]
}]


// 获取路由信息方法
let getRoutes = function() {
	createRoute(routes)
	return routes
}
// 自动生成路由
function createRoute(arr) {
	for (let i = 0; i < arr.length; i++) {
		if (!arr[i].component) return
		let val = removeTrailingIndex(arr[i].component)
		arr[i].name = arr[i].name || getRouteName(val)
		arr[i].path = arr[i].path || getRoutePath(val)
		let componentFun = import(`../views/${arr[i].component}.vue`)
		arr[i].component = () => componentFun
		if (arr[i].children && arr[i].children.length > 0) {
			createRoute(arr[i].children)
		}
	}
}
function removeTrailingIndex(str) {
	// 获取最后一个/的索引
	let index = str.lastIndexOf('/')
	let val = str.substring(index + 1, str.length)
	if (val === 'index') {
		return str.substring(index, -1)
	}
	return str
}

function getRouteName(val) {
	return val.replace(/\/_[a-z]+/g, '').replace(/\//g, '_')
}

function getRoutePath(val) {
	return `/${val.replace(/_/g, ':')}`
}

const router = new VueRouter({
	mode: 'history',
	routes: getRoutes()
})

export default router
