export default {
	uniqueCategories(state) {
		var categories = []
		var newCategories = []
		state.games.forEach((item) => {
			if (!newCategories.includes(item.category)) {
				newCategories.push(item.category)
				categories.push({category: item.category, category_handle: item.category_handle})
			}
		})
		return categories
	}
}